import CSelectSearch from '@/components/CSelectSearch'

const routesDictionary = {
  account: [
    'SettingsAccount',
    'SettingAccountDetails',
    'SettingCompanyDetails',
    'SettingEmployees',
    'SettingCompanyUsersIndex',
    'InviteEmployee',
    'InvitedEmployees',
    'SettingCompanyUsersEdit',
    'SettingPermissionGroups',
    'MyProfile'
  ],
  templates: [
    'SettingsTemplates',
    'SettingDocumentTemplate',
    'SettingEmailTemplates',
  ],
  project: [
    'SettingsProject',
    'SettingContractApprovals',
    'SettingScopeApproval',
    'SettingCompanyTypes',
    'SettingTaskPriorities',
  ],
}

export default {
  components: { CSelectSearch },
  data() {
    return {
      selectedSetting: null,
      routesDictionary,
      settingsRoutesConfig: {
        account: 'SettingsAccount',
        templates: 'SettingsTemplates',
        project: 'SettingsProject',
      },
    }
  },
  computed: {
    settingsList() {
      return [
        {
          value: 'account',
          label: 'My Company',
        },
        {
          value: 'templates',
          label: 'Templates',
        },
        {
          value: 'project',
          label: 'Project',
        },
      ]
    },
  },
  created() {
    this.setDefaultOption()
  },
  watch: {
    selectedSetting(setting) {
      if (!setting) return
      const currentRoute = this.settingsRoutesConfig[setting]

      if (this.routesDictionary[setting].includes(this.$route.name)) return

      this.$router.push({
        name: currentRoute,
      })
    },
  },
  methods: {
    setDefaultOption() {
      const routeName = this.$route.name

      Object.keys(this.routesDictionary).forEach((settingPart) => {
        return this.routesDictionary[settingPart].includes(routeName)
          ? (this.selectedSetting = settingPart)
          : null
      })
    },
  },
}
