<template>
  <div class="settings-project">
    <teleport v-if="mountedComponent" to="#settings-header">
      <TopNavigation :links="topNavLinks" />
    </teleport>

    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'Settings' }">Settings</router-link>
      </CBreadcrumbItem>

      <CBreadcrumbItem>
        <CSelectSearch
          v-model="selectedSetting"
          class="settings-project__select"
          :options="settingsList"
          :search="true"
        />
      </CBreadcrumbItem>
    </teleport>

    <router-view />
  </div>
</template>

<script>
import TopNavigation from '@/components/Page/TopNavigation'
import breadcrumbsMixin from '@/views/Setting/breadcrumbsMixin'
import Gate from '@/services/Gate/Gate'
import { notifyByPlan } from '@/services/Notify/Toasts'

export default {
  name: 'Index',
  components: { TopNavigation },
  mixins: [breadcrumbsMixin],
  data() {
    return {
      mountedComponent: false,
      canView: false,
      canEdit: false,
    }
  },
  computed: {
    topNavLinks() {
      return [
        {
          title: 'Contract Approvals',
          route: { name: 'SettingContractApprovals' },
          guard: () => {
            if (!this.canView) notifyByPlan()
            return this.canView
          },
        },
        {
          title: 'Scope Approvals',
          route: { name: 'SettingScopeApproval' },
          guard: () => {
            if (!this.canView) notifyByPlan()
            return this.canView
          },
        },
        {
          title: 'Company Types',
          route: { name: 'SettingCompanyTypes' },
          guard: () => {
            if (!this.canEdit) notifyByPlan()
            return this.canEdit
          },
        },
        // {
        //   title: 'Task Priorities',
        //   route: { name: 'SettingTaskPriorities' },
        //   guard: () => {
        //     if (!this.canView) notifyByPlan()
        //     return this.canView
        //   },
        // },
      ]
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.mountedComponent = true
      this.canView = await Gate.can('projectView', 'setting')
      this.canEdit = await Gate.can('edit', 'setting')
    })
  },
}
</script>

<style lang="scss" scoped>
.settings-project {
  &__select {
    width: 162px;
  }
}
</style>
